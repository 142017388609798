import { gql } from "@apollo/client";

export const SIGNIN_STORE_USER = `query storeUserSignIn($storeUserSignInInput: StoreUserSignInInput!) {
  storeUserSignIn(storeUserSignInInput: $storeUserSignInInput) {
    accessToken
    refreshToken
    type
    roles
    storeUser{
     stores{
        correlationId
        name
        planId
        allowedFeatures
        suggestedTipPercentages{
        tipPercentage
        }
        deliveryPolicies{
        startMile
        endMile
        deliveryCharge
        }
      }
      correlationId
      firstName
      lastName
      email
      phoneNumber
      employerName
      stores{
      correlationId
      name
      nonEnglishName
      primaryContactPhoneNumber
      primaryContactEmail
      primaryContactFirstName
      primaryContactLastName
      style
      employeeCount
      connectedAccountId
      bucketKeyName
      categories {
        correlationId
        name
      }
      operationHours {
        day
        startHour
        endHour
      }
      address {
        addressLine1
        addressLine2
        city
        state
        zipCode
      }
        employeeCount
        averageRating
        planId
        allowedFeatures
      }
    }
  }
}`;

export const SIGNIN_EMPLOYEE_USER = `query storeEmployeeSignIn($storeEmployeeSignInInput: StoreEmployeeSignInInput!) {
  storeEmployeeSignIn(storeEmployeeSignInInput: $storeEmployeeSignInInput) {
    type
    roles
    refreshToken
    accessToken
    storeUser {
      correlationId
      firstName
      lastName
      email
      phoneNumber
      bucketKeyName
      employerName
      stores{
        correlationId
        name
        planId
        allowedFeatures
      }
    }
  }
}`;

export const REGISTER_STORE_USER = `mutation registerStoreUser($createStoreUserInput: CreateStoreUserInput!) {
    registerStoreUser(createStoreUserInput: $createStoreUserInput) {
        correlationId
        firstName
        lastName
        email
        phoneNumber
        employerName
    }
}`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($resetPasswordInput: ResetPasswordInput!) {
    resetPassword(resetPasswordInput: $resetPasswordInput) {
      requestProcessed
    }
  }
`;

export const SAVE_NEW_PASSWORD = gql`
  mutation saveNewPassword($saveNewPasswordInput: SaveNewPasswordInput!) {
    saveNewPassword(saveNewPasswordInput: $saveNewPasswordInput) {
      message
    }
  }
`;

export const ACTIVATE_USER_ACCOUNT = gql`
  mutation activateUserAccount(
    $activateUserAccountInput: ActivateUserAccountInput!
  ) {
    activateUserAccount(activateUserAccountInput: $activateUserAccountInput) {
      activated
      message
    }
  }
`;

export const CREATE_RESTAURANT = `mutation createStore($createStoreInput: CreateStoreInput!) {
  createStore(createStoreInput: $createStoreInput) {
    name
    correlationId
    address {
      addressLine1
      addressLine2
      city
      state
      zipCode
    }
    operationHours {
      day
      startHour
      endHour
    }
    planId
    allowedFeatures
  }
}
`;

export const UPDATE_RESTAURANT = `mutation updateStore($updateStoreInput: UpdateStoreInput!) {
  updateStore(updateStoreInput: $updateStoreInput) {
    correlationId
    name
    nonEnglishName
    primaryContactFirstName
    primaryContactLastName
    primaryContactEmail
    primaryContactPhoneNumber
    address {
      addressLine1
      addressLine2
      city
      state
      zipCode
    }
    operationHours {
      day
      startHour
      endHour
    }
       suggestedTipPercentages{
        tipPercentage
        }
    deliveryPolicies{
      startMile
      endMile
      deliveryCharge
    }
    planId
    allowedFeatures
  }
}
`;

export const UPLOAD_RESTAURANT_IMAGE = `mutation createUploadPreSignedUrlForStore($createStorePreSignedUrlInput: CreateStorePreSignedUrlInput!) {
  createUploadPreSignedUrlForStore(createStorePreSignedUrlInput: $createStorePreSignedUrlInput) {
    preSignedUrl
  }
}`;

export const UPLOAD_CATEGORY_IMAGE = `mutation createUploadPreSignedUrlForCategory($createCategoryPreSignedUrlInput: CreateCategoryPreSignedUrlInput!) {
  createUploadPreSignedUrlForCategory(createCategoryPreSignedUrlInput: $createCategoryPreSignedUrlInput) {
    preSignedUrl
  }
}`;

export const UPLOAD_COMBO_IMAGE = `mutation createUploadPreSignedUrlForCombo($createComboPreSignedUrlInput: CreateComboPreSignedUrlInput!){
  createUploadPreSignedUrlForCombo(createComboPreSignedUrlInput: $createComboPreSignedUrlInput) {
    preSignedUrl
  }
}`;

export const UPLOAD_CATEGORY_ITEM_IMAGE = `mutation createUploadPreSignedUrlForItem($createItemPreSignedUrlInput: CreateItemPreSignedUrlInput!) {
  createUploadPreSignedUrlForItem(createItemPreSignedUrlInput: $createItemPreSignedUrlInput) {
    preSignedUrl
  }
}`;

export const UPLOAD_EMPLOYEE_IMAGE = `mutation createUploadPreSignedUrlForUser($createUserPreSignedUrlInput: CreateUserPreSignedUrlInput!) {
  createUploadPreSignedUrlForUser(createUserPreSignedUrlInput: $createUserPreSignedUrlInput) {
    preSignedUrl
  }
}`;

export const REFRESH_ACCESS_TOKEN = `mutation refreshAccessToken($refreshAccessTokenInput: RefreshAccessTokenInput!){
    refreshAccessToken(refreshAccessTokenInput: $refreshAccessTokenInput) {
			accessToken
    	refreshToken
    }
}`;

export const CREATE_REVIEW = `mutation createReview($createReviewInput: CreateReviewInput!) {
  createReview(createReviewInput: $createReviewInput) {
    createdAt
    correlationId
    storeCorrelationId
    content
    authorId
    replyTo
    rating
  }
}`;

export const ADD_CATEGORY = `mutation addCategory($addCategoryInput: AddCategoryInput!){
  addCategory(addCategoryInput: $addCategoryInput){
    name
    correlationId
  }
}`;

export const UPDATE_CATEGORY = `mutation updateCategory($updateCategoryInput: UpdateCategoryInput!) {
  updateCategory(updateCategoryInput: $updateCategoryInput) {
    correlationId
    name
    nonEnglishName
  }
}
`;

export const ADD_ITEM = `mutation addItem($addItemInput: AddItemInput!){
  addItem(addItemInput: $addItemInput){
    englishName
    nonEnglishName
    englishDescription
    nonEnglishDescription
    price
    correlationId
    bucketKeyName
   itemOptions  {
       correlationId
				name
				description
				renderSequence
				isRequired
        itemOptionElements {
        correlationId
				name
				description
				renderSequence
				price
          }    
      }
  }
}
`;

export const ADD_OPTION = `mutation addItemOption($addItemOptionInput: AddItemOptionInput!){
addItemOption(addItemOptionInput: $addItemOptionInput){
	correlationId
 }
}
`;

export const UPDATE_ITEM = `mutation updateItem($updateItemInput: UpdateItemInput!){
updateItem(updateItemInput: $updateItemInput){
  correlationId
  englishName
  nonEnglishName
  englishDescription
  nonEnglishDescription
  price
  bucketKeyName
  abbreviation,
  taxRate,
  tag,
  itemOptions {
    correlationId
    name
    description
    renderSequence
    isRequired
    itemOptionElements {
      correlationId
      name
      description
      renderSequence
      price
    }
  }
 }
}
`;

export const UPDATE_ITEM_OPTION = `mutation updateItemOption($updateItemOptionInput: UpdateItemOptionInput!){
  updateItemOption(updateItemOptionInput: $updateItemOptionInput){
    correlationId
    name
    description
    renderSequence
    isRequired
   }
  }
`;

export const ADD_ITEM_OPTION = `mutation addItemOption($addItemOptionInput: AddItemOptionInput!){
  addItemOption(addItemOptionInput: $addItemOptionInput){
    correlationId
    name
    description
    renderSequence
    isRequired
    itemOptionElements {
      correlationId
      name
      description
      renderSequence
      price
    }
   }
  }
`;

export const UPDATE_ITEM_OPTION_ELEMENT = `mutation updateItemOptionElement($updateItemOptionElementInput: UpdateItemOptionElementInput!){
  updateItemOptionElement(updateItemOptionElementInput: $updateItemOptionElementInput){
    correlationId
    name
    description
    renderSequence
    price
   }
  }
`;

export const ADD_ITEM_OPTION_ELEMENT = `mutation addItemOptionElement($addItemOptionElementInput: AddItemOptionElementInput!){
  addItemOptionElement(addItemOptionElementInput: $addItemOptionElementInput){
    correlationId
    name
    description
    renderSequence
    price
   }
  }
`;

export const ADD_TABLE = `mutation createStoreTable($createStoreTableInput: CreateStoreTableInput!){
  createStoreTable(createStoreTableInput: $createStoreTableInput){
    storeCorrelationId
    tableNumber
    tableSize
    correlationId
    status
  }
}
`;

export const UPDATE_TABLE = `mutation updateStoreTable($updateStoreTableInput: UpdateStoreTableInput!){
    updateStoreTable(updateStoreTableInput: $updateStoreTableInput){
      storeCorrelationId
      tableNumber
      tableSize
      correlationId
      status
    }
  }
`;

export const ADD_COMBO = `mutation createCombos($createCombosInput: CreateCombosInput!){
  createCombos(createCombosInput: $createCombosInput){
      correlationId
      englishName
      nonEnglishName
      englishDescription
      nonEnglishDescription
      price
      itemList{
      	correlationId
      	abbreviation
      	englishName
      	nonEnglishName
      	englishDescription
        nonEnglishDescription
        price
        taxRate
        tag
        bucketKeyName
        itemOptions{
          correlationId
          name
          description
          renderSequence
          isRequired
          itemOptionElements{
            correlationId
            name
            description
            renderSequence
            price
          }
        }
      }
    }
}
`;

export const UPDATE_COMBO = `mutation updateCombo($updateComboInput: UpdateComboInput!){
    updateCombo(updateComboInput: $updateComboInput){
      correlationId
      englishName
      nonEnglishName
      englishDescription
      nonEnglishDescription
      price
      taxRate
      itemList{
      	correlationId
      	abbreviation
      	englishName
      	nonEnglishName
      	englishDescription
        nonEnglishDescription
        price
        taxRate
        tag
        bucketKeyName
        itemOptions{
          correlationId
          name
          description
          renderSequence
          isRequired
          itemOptionElements{
            correlationId
            name
            description
            renderSequence
            price
          }
        }
      }
    }
  }
`;

export const ADD_PROMOTION = `mutation createPromotionCode($createPromotionCodeInput: CreatePromotionCodeInput!){
  createPromotionCode(createPromotionCodeInput: $createPromotionCodeInput){
   	message
  }
}
`;

export const ADD_EMPLOYEE = `mutation registerStoreEmployee($createStoreEmployeeInput: CreateStoreEmployeeInput!){
  registerStoreEmployee(createStoreEmployeeInput: $createStoreEmployeeInput){
      correlationId
    }
}
`;

export const UPDATE_EMPLOYEE = `mutation updateStoreEmployee($updateStoreEmployeeInput: UpdateStoreEmployeeInput!){
    updateStoreEmployee(updateStoreEmployeeInput: $updateStoreEmployeeInput){
			  correlationId
        firstName
        lastName
        email
        phoneNumber
        employerName
        bucketKeyName
        stores{
          name
          correlationId
        }
    }
  }
`;

export const UPDATE_CUSTOMER_ORDER = `mutation updateCustomerOrder($updateCustomerOrderInput: UpdateCustomerOrderInput!){
    updateCustomerOrder(updateCustomerOrderInput: $updateCustomerOrderInput){
			 correlationId
    }
  }
`;

export const CREATE_EMPLOYEE_ORDER = `mutation createCustomerOrder($createCustomerOrderInput: CreateCustomerOrderInput!){
  createCustomerOrder(createCustomerOrderInput: $createCustomerOrderInput){
    correlationId
    createdAt
    customerCorrelationId
    customerFirstName
    customerLastName
    customerEmail
    customerPhoneNumber
    storeCorrelationId
    storeName
    isGuest
    orderStatus
    orderType
    tableNumber
    totalPrice
    totalItemQuantity
    customerNote
    serverFirstName
    serverLastName
    serverEmployeeCorrelationId
    paid
    tip
    payment{
      paymentDate
      paymentAmount
      paymentMethodType
      paymentMethodBrand
      last4Digits
      paymentStatus
    }
    items{
      correlationId
      quantity
      isCombo
      englishName
      nonEnglishName
      englishDescription
      nonEnglishDescription
      price
      taxRate
      bucketKeyName
      itemOptionElements
      itemCorrelationId
    }

  }
}
`;

export const ADD_CUSTOMER = `mutation createCustomer($createCustomerInput: CreateCustomerInput!){
    createCustomer(createCustomerInput: $createCustomerInput) {
        correlationId 
    }
}
`;

export const UPDATE_CUSTOMER = `mutation updateCustomer($updateCustomerInput: UpdateCustomerInput!){
    updateCustomer(updateCustomerInput: $updateCustomerInput) {
        correlationId
    		firstName
    		lastName
    		email
    		phoneNumber
        address{
            addressLine1
            addressLine2
            city
            state
            zipCode
        }
    }
}
`;

export const CAPTURE_PAYMENT = `mutation captureCustomerOrderPayment($captureCustomerOrderPaymentInput: CaptureCustomerOrderPaymentInput!){
    captureCustomerOrderPayment(captureCustomerOrderPaymentInput: $captureCustomerOrderPaymentInput) {
`;

export const CAPTURE_TIP = `mutation captureCustomerOrderTip($captureCustomerOrderTipInput: CaptureCustomerOrderTipInput!){
  captureCustomerOrderTip(captureCustomerOrderTipInput: $captureCustomerOrderTipInput) {
  correlationId
  createdAt
  customerCorrelationId
  customerFirstName
  customerLastName
  customerEmail
  customerPhoneNumber
  storeCorrelationId
  storeName
  isGuest
  orderStatus
  orderType
  tableNumber
  totalPrice
  totalItemQuantity
  customerNote
  serverFirstName
  serverLastName
  serverEmployeeCorrelationId
  paid
  items{
    correlationId
    quantity
    isCombo
    englishName
    nonEnglishName
    englishDescription
    nonEnglishName
    price
    taxRate
    bucketKeyName
    itemOptionElements
    itemCorrelationId
  }
  tip
  payment{
    paymentDate
    paymentAmount
    paymentMethodType
    paymentMethodBrand
    last4Digits
    paymentStatus
  }
  }
}`;

export const DOWNLOAD_RECEIPT = `
          query receiptByCustomerOrderCorrelationIdForCustomers($customerOrderCorrelationId: UUID!) {
            receiptByCustomerOrderCorrelationIdForCustomers(
              customerOrderCorrelationId: $customerOrderCorrelationId
            )
          }
`;