import React from "react";
import { STATES } from "src/constants/timezone";

const StateProvince = ({ value, onChange, onBlur, error, name = "stateProvince" }: any) => {
    return (
        <div>
            <select
                id={name}
                name={name}
                value={value} // This should be the state name (e.g., "Texas")
                onChange={onChange}
                onBlur={onBlur}
                className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2 cursor-pointer"
            >
                <option value="" disabled>
                    Select your State
                </option>
                {Object.entries(STATES).map(([state, timezone]) => (
                    <option key={state} value={state}> {/* Use state name as the value */}
                        {state}
                    </option>
                ))}
            </select>
            {error && error.touched.name && error.errors.name ? (
                <div className="text-orange text-xs mt-2">
                    {error.errors.name}
                </div>
            ) : null}
        </div>
    );
};

export default StateProvince;